import Card1 from "../components/Card1";
import Card2 from "../components/Card2";

import FAQ_QA from "../components/FAQ_QA";
import DrawLine from "../components/DrawLine";
import MainView from "../images/careerbanner.jpg";
import Logo from "../images/common/logomain.png";
import Footer from "../components/Footer";
import careerDoc from "../images/doctors/careerDoc.png";
import tick from "../images/doctors/tick.svg";
import team from "../images/doctors/team.svg";

import "../css/career.scss";

const dummy_data = [
  {
    question: {
      position: "Medical Surgical Floor Nurse",
      place: "Pauls Valley, OK",
      dept: "Surgery",
    },
    shortDesc:
      "Responsible for assessing, intervening and stabilizing a variety of trauma and illnesses with decisive action. Due to the range of medical conditions that may require urgent treatment and care, they must be knowledgeable about general as well as specific health issues. Properly understand triage in order to prioritize injuries based on medical need. Able to make quick and accurate assessments about incoming patients, including both physical and mental health conditions.",
    detailDesc: [
      { title: "Job Type", details: "Full-time, PRN, Part-time" },
      {
        title: "Benefits",
        details: [
          "Dental insurance",
          "Health insurance",
          "Life insurance",
          "Paid time off",
          "Vision insurance",
        ],
      },
      {
        title: "Medical specialties",
        details: "Medical-Surgical",
      },
      {
        title: "Physical setting",
        details: "Hospital",
      },
      {
        title: "Salary",
        details: " $30.00 - $55.00 per hour",
      },
      {
        title: "Weekly schedule",
        details: "Rotating weekends",
      },
      {
        title: "COVID- 19 considerations",

        details: "No vaccination required",
      },
      {
        title: "Schedule",
        details: ["Day shift", "Evening shift", "Night shift"],
      },
      {
        title: "Ability to commute/relocate",
        details: [
          "Pauls Valley OK, 73075: Reliably commute or planning to relocate before starting work (Required)",
        ],
      },
      {
        title: "Experience",
        details: "Nursing: 1 year (Required)",
      },
      {
        title: "License/Certification",
        details: ["BLS Certification (Preferred)", "RN (Required)"],
      },
    ],
  },
  {
    question: {
      position: "Medical Assistant",
      place: "Edmond, OK",
      dept: "Administration",
    },
    shortDesc:
      "Our organization is hiring a Medical Assistant to support the medical team, in order to provide patients with high-quality medical care. You will assist medical staff during examinations, assisting with various medical tasks and running errands. Medical Assistant responsibilities include keeping meticulous records of patient visits, as well as preparing patients for examinations. Strong communication skills and attention to detail are important for this job, as you will deal with patients and medical staff. The ideal candidate should have a good professional demeanor, because you will record information about patient’s conditions and vital signs, among others. Ultimately, your goal is to facilitate positive patient experiences and help enhance the quality of our clients' lives.",
    detailDesc: [
      { title: "Job Type", details: "Full-time" },
      {
        title: "Benefits",
        details: [
          "Dental insurance",
          "Health insurance",
          "Paid time off",
          "Vision insurance",
        ],
      },
      {
        title: "Salary",
        details: "$15.00 - $18.00 per hour",
      },
      {
        title: "Medical specialties",
        details: ["Dermatology"],
      },
      {
        title: "Schedule",
        details: [
          "8 hour shift",
          "Day shift",
          "Evening shift",
          "Monday to Friday",
        ],
      },
      {
        title: "Ability to commute/relocate",
        details: [
          "Edmond, OK 73034: Reliably commute or planning to relocate before starting work (Required)",
        ],
      },
      {
        title: "Experience",
        details: [
          "EMR systems: 1 year (Preferred)",
          "Vital signs: 1 year (Preferred)",
        ],
      },
      {
        title: "License/Certification",
        details: [
          "BLS Certification (Preferred)",
          "Certified Medical Assistant (Preferred)",
        ],
      },
    ],
  },
  {
    question: {
      position: "ER Nurse",
      place: "Pauls Valley, OK",
      dept: "ER",
    },
    shortDesc:
      "Responsible for assessing, intervening and stabilizing a variety of trauma and illnesses with decisive action. Due to the range of medical conditions that may require urgent treatment and care, they must be knowledgeable about general as well as specific health issues. Properly understand triage in order to prioritize injuries based on medical need. Able to make quick and accurate assessments about incoming patients, including both physical and mental health conditions.",
    detailDesc: [
      { title: "Job Type", details: "Full-time, PRN, Part-time" },
      {
        title: "Benefits",
        details: [
          "Dental insurance",
          "Health insurance",
          "Life insurance",
          "Paid time off",
          "Vision insurance",
        ],
      },
      {
        title: "Medical specialties",

        details: "Emergency Medicine",
      },
      {
        title: "Physical setting",
        details: "Hospital",
      },
      {
        title: "Salary",
        details: " $30.00 - $55.00 per hour",
      },
      {
        title: "Weekly schedule",
        details: "Rotating weekends",
      },
      {
        title: "Schedule",
        details: ["Day shift", "Evening shift", "Night shift"],
      },
      {
        title: "Ability to commute/relocate",
        details: [
          "Pauls Valley OK, 73075: Reliably commute or planning to relocate before starting work (Required)",
        ],
      },
      {
        title: "Experience",
        details: "Nursing: 1 year (Required)",
      },
      {
        title: "License/Certification",
        details: ["BLS Certification (Preferred)", "RN (Required)"],
      },
    ],
  },
  {
    question: {
      position: "Floor Nurse",
      place: "Pauls Valley, OK",
      dept: "ER",
    },
    shortDesc:
      "Responsible for assessing, intervening and stabilizing a variety of trauma and illnesses with decisive action. Due to the range of medical conditions that may require urgent treatment and care, they must be knowledgeable about general as well as specific health issues. Properly understand triage in order to prioritize injuries based on medical need. Able to make quick and accurate assessments about incoming patients, including both physical and mental health conditions.",
    detailDesc: [
      { title: "Job Type", details: "Full-time, PRN, Part-time" },
      {
        title: "Benefits",
        details: [
          "Dental insurance",
          "Health insurance",
          "Life insurance",
          "Paid time off",
          "Vision insurance",
        ],
      },
      {
        title: "Physical setting",
        details: "Hospital",
      },
      {
        title: "Salary",
        details: " $30.00 - $55.00 per hour",
      },
      {
        title: "Weekly schedule",
        details: "Rotating weekends",
      },
      {
        title: "COVID- 19 considerations",

        details: "No vaccination required",
      },
      {
        title: "Schedule",
        details: ["Day shift", "Evening shift", "Night shift"],
      },
      {
        title: "Ability to commute/relocate",
        details: [
          "Pauls Valley OK, 73075: Reliably commute or planning to relocate before starting work (Required)",
        ],
      },
      {
        title: "Experience",
        details: "Nursing: 1 year (Required)",
      },
      {
        title: "License/Certification",
        details: ["BLS Certification (Preferred)", "RN (Required)"],
      },
    ],
  },
  {
    question: {
      position: "CNA (Nursing Assistant)",
      place: "Pauls Valley, OK",
      dept: "Surgery",
    },
    shortDesc:
      "CNA (Nursing Assistant) receives patient care assignment from the registered nurse (RN) in charge. Initiates delegated tasks based on demonstrated competence in performing nursing activities for specific patient age group served. Collects and documents data for the admission assessment, reassessment and/or scheduling appointments or surgeries at specific times for patient population. May document and provide appropriate data to the RN and/or LPN for analysis and validation of patient's response to care or treatment and when significant change occurs in the patient's condition.",
    detailDesc: [
      { title: "Job Type", details: "Full-time, PRN, Part-time" },
      {
        title: "Benefits",
        details: [
          "Dental insurance",
          "Health insurance",
          "Paid time off",
          "Vision insurance",
        ],
      },
      {
        title: "Physical setting",
        details: "Hospital",
      },
      {
        title: "Medical specialties",
        details: "Medical-Surgical",
      },
      {
        title: "Salary",
        details: " $14.00 - $18.00 per hour",
      },
      {
        title: "Weekly schedule",
        details: "Rotating weekends",
      },
      {
        title: "COVID- 19 considerations",

        details: "No vaccination required",
      },
      {
        title: "Schedule",
        details: [
          "Day shift",
          "Evening shift",
          "Night shift",
          "Overnight shift",
        ],
      },
      {
        title: "Ability to commute/relocate",
        details: [
          "Pauls Valley OK, 73075: Reliably commute or planning to relocate before starting work (Required)",
        ],
      },
      {
        title: "License/Certification",
        details: ["CPR Certification (Preferred)"],
      },
    ],
  },
  {
    question: {
      position: "ER Tech",
      place: "Pauls Valley, OK",
      dept: "ER",
    },
    shortDesc: "Emergency Technician needed (CNA is helpful but not needed).",
    detailDesc: [
      { title: "Job Type", details: "Full-time, PRN, Part-time" },
      {
        title: "Benefits",
        details: [
          "Dental insurance",
          "Health insurance",
          "Paid time off",
          "Vision insurance",
        ],
      },
      {
        title: "Physical setting",
        details: "Hospital",
      },
      {
        title: "Medical specialties",
        details: "Emergency Medicine",
      },
      {
        title: "Salary",
        details: " $14.00 - $18.00 per hour",
      },
      {
        title: "Weekly schedule",
        details: "Rotating weekends",
      },
      {
        title: "COVID- 19 considerations",

        details: "No vaccination required",
      },
      {
        title: "Experience",

        details: "Vital signs: 1 year (Preferred)",
      },
      {
        title: "Schedule",
        details: [
          "Day shift",
          "Evening shift",
          "Night shift",
          "Overnight shift",
        ],
      },
      {
        title: "Ability to commute/relocate",
        details: [
          "Pauls Valley OK, 73075: Reliably commute or planning to relocate before starting work (Required)",
        ],
      },
      {
        title: "License/Certification",
        details: [
          "CPR Certification (Preferred)",
          "CPR Certification (Preferred)",
        ],
      },
    ],
  },
  {
    question: {
      position: "Part Time Ultrasound Tech",
      place: "Pauls Valley",
      dept: "Radiology",
    },
    shortDesc:
      "Vascular, Echo technologist, general experience a plus.An Echocardiography/ Vascular Sonographer is a Diagnostic Medical Sonographer Professional that is qualified by professional credentialing and academic and clinical experience to provide diagnostic patient care services using ultrasound and related diagnostic procedures.The scope of practice of the Diagnostic Medical Sonographer includes those procedures acts and processes permitted by law for which the individual has received education and clinical experience has demonstrated competency and has completed the appropriate ARDMS certification which is the standard of practice in cardiac ultrasound.Demonstrates the cognitive skills required to perform comprehensive echocardiograph imaging.Demonstrates the technical skills and abilities required to perform comprehensive echocardiograph exam and vascular exam",
    detailDesc: [
      { title: "Job Type", details: "Part-time" },
      {
        title: "Education",
        details:
          "Must meet the education requirement for Echocardiography Sonographer certification with either CCI or ARDMS; Bachelor's Degree preferred.",
      },
      {
        title: "Salary",
        details: "$35.00 - $37.00 per hour",
      },
      {
        title: "Licensure",
        details: [
          "RCS or RDCS with CCI",
          "General ultrasound technologist a plus",
          "Perform lower extremity venous duplex ultrasound exams for DVT",
          "Perform ABI exams for peripheral arterial disease",
        ],
      },

      {
        title: "Schedule",
        details: ["10 hour shift", "12 hour shift", "8 hour"],
      },
      {
        title: "Ability to commute/relocate",
        details: [
          "Pauls Valley, OK 73075: Reliably commute or planning to relocate before starting work(Preferred)",
        ],
      },
      {
        title: "Experience",
        details: "2 years relevant experience preferred",
      },
      {
        title: "License/Certification",
        details: ["BLS Certification (Preferred)"],
      },
    ],
  },
  {
    question: {
      position: "PRN Laboratory Technician",
      place: "Pauls Valley",
      dept: "Laboratory",
    },
    shortDesc:
      "Our company is searching for an individual with a detailed working knowledge of laboratory equipment and testing procedures. The successful candidate will apply their talents on a daily basis, making an immediate contribution to our team. We are seeking a Laboratory Technician who has the ability to make correct decisions under difficult situations and can effectively communicate with others in a professional manner.",
    detailDesc: [
      { title: "Job Type", details: "Part-time" },
      {
        title: "Responsibilities",
        details: [
          "Test samples",
          "Organize items in a lab.",
          "Maintain lab instruments.",
          "Work with a team of lab technicians.",
          "Adaptability to new equipment",
          "Reliability / Dependability",
          "Maintain QC logs",
          "Maintain Daily Checks",
        ],
      },
      {
        title: "Salary",
        details: "$10.94 - $36.51 per hour",
      },
      {
        title: "Benifits",
        details: "Life insurance",
      },

      {
        title: "Schedule",
        details: ["Night shift", "Weekend availability"],
      },
      {
        title: "Ability to commute/relocate",
        details: [
          "Pauls Valley, OK 73075: Reliably commute or planning to relocate before starting work(Preferred)",
        ],
      },
      {
        title: "Laboratory experience",
        details: "1 year experience preferred",
      },
      {
        title: "License/Certification",
        details: ["BLS Certification (Preferred)"],
      },
    ],
  },
  {
    question: {
      position: "PRN Xray/ CT tech",
      place: "Pauls Valley",
      dept: "Radiology",
    },
    shortDesc:
      "Seeking a candidate to fill PRN position for X-ray/CT Tech. Hours will vary.The Radiographer/CT technologist is responsible for performing routine to complex CT procedures for the diagnosis of disease and injury in accordance with established protocols under the direction/supervision of a radiologist in a radiology department where a wide variety of exams are regularly performed using ionized radiation. 64slice siemens CT scanner. Will be required to perform general x-ray also.The X- Ray / CT technologist maintains a high standard of medical ethic at all times and is self - motivated to increase level of understanding and knowledge of the field, disease, and new procedures as they evolve.",
    detailDesc: [
      { title: "Job Type", details: "Part-time" },
      {
        title: "Education",
        details: "Min. Assoc. Science Degree in Radiological Science",
      },
      {
        title: "Salary",
        details: " $27.00 - $47.93 per hour",
      },
      {
        title: "Licensure",
        details: ["ARRT Registered Required or registry eligible"],
      },

      {
        title: "Schedule",
        details: ["10 hour shift", "12 hour shift", "8 hour shift"],
      },
      {
        title: "Ability to commute/relocate",
        details: [
          "Pauls Valley, OK 73075: Reliably commute or planning to relocate before starting work(Preferred)",
        ],
      },
      {
        title: "Experience",
        details: "Willing to accept a student",
      },
      {
        title: "License/Certification",
        details: ["BLS Certification (Preferred)"],
      },
    ],
  },
];
const Career = () => {
  console.log("In Career page");
  return (
    <div className="career">
      {/* <div
        className="topDesignElement1Capsule"
        data-aos="new-animation"
        data-aos-delay="10"
        data-aos-duration="1000"
      >
        <div className="topDesignElement1"></div>
      </div> */}
      {/* <div className="topDesignElement2">
        <div className="topDesignElement2-container">
          <div className="container2">
            <div className="topPart"></div>
            <div className="bottomPart"></div>
          </div>
          <div
            className="topDesignElement3"
          // data-aos="custom-img-animate"
          // data-aos-duration="2500"
          // data-aos-once="true"
          >
            <img src={MainView} alt="" />
          </div>
        </div>
      </div> */}
      <Card2>
        <div className="section1">
          <div class="career-content-container">
            <div
              className="section1-title"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              Join us & Become a part of our story
            </div>
            <div
              className="section1-para"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              We inspire each other every day by pushing limits of what's
              possible in the field of health and medicine.
            </div>
            <div
              className="section1-button"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              <button className="realButton">Apply Now</button>
            </div>
          </div>
          <div class="careerDoc-container">
            <div class="internal-careerDoc">
              <div class="careerdoctor">
                <img src={careerDoc} alt="mainview" />
              </div>

              <div class=" Cobject Cobject1">
                <div class="Cobject1-container">
                  <div class="team">
                    <img src={team} alt="tick" className="icon" />
                  </div>
                  <div class="object-title">Join Our Dynamic Team</div>
                </div>
              </div>

              <div class=" Cobject Cobject2">
                <div class="Cobject1-container">
                  <div class="ticker">
                    <img src={tick} alt="tick" className="icon" />
                  </div>
                  <div class="object-title">Unleash Your Potential</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card2>

      <Card2>
        <section className="section-2">
          <div
            class="section2-title"
            data-aos="fade-up"
            data-aos-delay="10"
            data-aos-duration="1000"
          >
            Available Positions
          </div>
          <div className="section-2_container">
            <div className="section-2_container--description">
              {dummy_data.map((data, index) => (
                <>
                  {index !== 0 && (
                    <div>
                      {/* <div data-aos="fade-up" data-aos-delay="200"> */}
                      {/* <DrawLine width="100%" margin="0" height="0.2px" /> */}
                    </div>
                  )}
                  <FAQ_QA
                    question={data.question}
                    shortDesc={data.shortDesc}
                    detailDesc={data.detailDesc}
                  />
                </>
              ))}
            </div>
          </div>
        </section>
      </Card2>
      <Footer />
    </div>
  );
};

export default Career;
