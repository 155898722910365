import "../css/footer.scss";
import { useEffect } from "react";
import Card1 from "../components/Card1";
import LogoFooter from "../images/common/logomain.png";
import Bgsection5 from "../images/home/background.svg";
import location1 from "../images/home/Location1.png";
import call1 from "../images/home/Call1.png";
import map from "../images/home/map.png";
import c from "../images/home/c.png";
import Card2 from "../components/Card2";
import Map from "../components/maps";

// import AOS from "aos";
// import "aos/dist/aos.css";
const Footer = () => {
  // useEffect(() => {
  //   AOS.init({
  //     startEvent: "DOMContentLoaded",
  //   });
  // }, []);
  return (
    <div className="footer">
      <div class="footer-top">
        <img src={Bgsection5} alt="" />
      </div>
      <div className="footer-container">
        <Card2>
          <div class="footer-middle">
            <div class="content1-container">
              <div class="content1">
                <div
                  className="content1-heading"
                  data-aos="fade-up"
                  data-aos-delay="10"
                  data-aos-duration="1000"
                >
                  Resources
                </div>
                <div
                  className="content1-para"
                  data-aos="fade-up"
                  data-aos-delay="10"
                  data-aos-duration="1000"
                >
                  Blog
                </div>
                <div
                  className="content1-para"
                  data-aos="fade-up"
                  data-aos-delay="10"
                  data-aos-duration="1000"
                >
                  Online bill pay options
                </div>
                <div
                  className="content1-para"
                  data-aos="fade-up"
                  data-aos-delay="10"
                  data-aos-duration="1000"
                >
                  Scheduling options
                </div>
                <div
                  className="content1-para"
                  data-aos="fade-up"
                  data-aos-delay="10"
                  data-aos-duration="1000"
                >
                  Multi-Lingual support
                </div>
                <div
                  className="content1-para"
                  data-aos="fade-up"
                  data-aos-delay="10"
                  data-aos-duration="1000"
                >
                  News/Press releases
                </div>
              </div>

              <div className="content2">
                <div className="content2-part1">
                  <div
                    className="content2-heading"
                    data-aos="fade-up"
                    data-aos-delay="10"
                    data-aos-duration="1000"
                  >
                    Location
                  </div>
                  <div
                    className="content2-content"
                    data-aos="fade-up"
                    data-aos-delay="10"
                    data-aos-duration="1000"
                  >
                    <div className="icon">
                      <img src={location1} alt="location" />
                    </div>
                    <div class="content2-details">
                      100,Valley Drive, Pauls Valley,OK 73075
                    </div>
                  </div>
                </div>

                <div className="content2-part1" id="contact-anchor">
                  <div
                    className="content2-heading"
                    data-aos="fade-up"
                    data-aos-delay="10"
                    data-aos-duration="1000"
                  >
                    Contact
                  </div>
                  <div
                    className="content2-content"
                    data-aos="fade-up"
                    data-aos-delay="10"
                    data-aos-duration="1000"
                  >
                    <div className="icon">
                      <img src={call1} alt="call" />
                    </div>
                    <div class="content2-details">(405)866-5100</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="content3"
              data-aos="fade-left"
              // data-aos-anchor="#contact-anchor"
              data-aos-anchor-placement="center-bottom"
              data-aos-offset="10"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <div className="internal-container">
                {/* <img src={map} alt="map" /> */}
                <Map />
              </div>
            </div>
          </div>
        </Card2>
      </div>

      <div class="bottom-container">
        <Card2>
          <div className="footer-bottom">
            <div className="left">
              <div className="left-icon">
                <img src={c} alt="icon" />
              </div>
              <div className="left-heading">Valley Community Hospital 2022</div>
            </div>
            <div className="right">Term & Conditions</div>
          </div>
        </Card2>
      </div>
    </div>
  );
};

export default Footer;
