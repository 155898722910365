import Coming_Soon from "../images/common/COMING_SOON_BIG.png"
import "../css/home.scss";
import "../css/doctor.scss";
const AboutUs = () => {
  console.log("In About Us page");
  return <div className="home_comingsoon"><div className="bluecruve"></div> <div className="comingsoon">
    <img src={Coming_Soon} alt="" />
  </div></div>;
};

export default AboutUs;
