import React, { useState, useRef, useCallback, useEffect } from "react";
import Card2 from "../components/Card2";
import Map from "../components/maps";
import InputTextField from "../components/InputTextField";
import TextAreaField from "../components/textAreaField";
import MainView from "../images/contactUs/contact_doctor.png";
import tick from "../images/contactUs/tick.png";
import contact from "../images/contactUs/contact.png";
import Bgsection5 from "../images/home/background.svg";
import detailsDoc from "../images/contactUs/details_doc.png";
import location from "../images/contactUs/location.png";
import Message from "../images/contactUs/message.png";
import phone from "../images/contactUs/phone.png";
import Footer from "../components/Footer";
import "../css/contactUs.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { Icon } from "@iconify/react";

const ContactUs = () => {
  const error_message = {
    fullName: "",
    emailId: "",
    complaintDoctorName: "",
    phoneNumber: "",
    query: "",
  };

  const resetRefs = {
    fullName: useRef(null),
    emailId: useRef(null),
    complaintDoctorName: useRef(null),
    phoneNumber: useRef(null),
    query: useRef(null),
  };

  const [inputData, setInputData] = useState({
    fullName: "",
    emailId: "",
    complaintDoctorName: "",
    phoneNumber: "",
    query: "",
    // message:"",
    // gender:"Male"
  });
  const [message, setMessage] = useState("");
  const [isVerified, setIsVerified] = useState("");
  // const formData = {
  //   name: inputData.fullName,
  //   email: inputData.emailId,
  //   doctorname: inputData.complaintDoctorName,
  //   query:inputData.query,,
  //   g_recaptcha_response: isVerified,
  //   phonenumber: inputData.phoneNumber,
  // };

  const [inputDataValidity, setInputDataValidity] = useState({
    fullName: true,
    emailId: true,
    complaintDoctorName: true,
  });
  const alwaysValidated = (value) => {
    return true;
  };
  const noCharValidateCheck = (charValue) => {
    return true;
  };
  const resetCaptcha = () => {
    // maybe set it till after is submitted
    captcha.reset();
  };
  let captcha;
  const onChange = (value) => {
    console.log(value);
  };
  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };
  const storeData = useCallback((fieldName, value, validityStatus) => {
    setInputData((prevState) => {
      // console.log(prevState);
      return { ...prevState, [fieldName]: value };
    });
    setInputDataValidity((prevState) => ({
      ...prevState,
      [fieldName]: validityStatus,
    }));
  }, []);

  const FORMSPARK_ACTION_URL = "https://submit-form.com/7V5O4RXD";
  const onSubmit = async (e) => {
    e.preventDefault();

    // if (isVerified != "") {
    // console.log(formData);
    await fetch(FORMSPARK_ACTION_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: inputData.fullName,
        email: inputData.emailId,
        doctorname: inputData.complaintDoctorName,
        message: inputData.query,
        g_recaptcha_response: isVerified,
        phonenumber: inputData.phoneNumber,
      }),
    });
    alert("Form submitted");
    console.log("Form submitted successfully!");
    Object.keys(resetRefs).forEach((value) => {
      resetRefs[value].current.reset_data();
    });
    resetCaptcha();
    const resetCaptcha = () => {
      // maybe set it till after is submitted
      captcha.reset();
      // };
      // maybe set it till after is submitted
      captcha.reset();
    };
    setMessage("");
    // } else {
    //   // Handle reCAPTCHA verification failure
    //   console.log("Please verify the reCAPTCHA");
    // }
  };

  const handleRecaptchaChange = (response) => {
    if (response) {
      setIsVerified(response);
    } else {
      setIsVerified("");
    }
  };

  const contactDetails = [
    {
      icon: phone,
      heading: "Phone no",
      para: "(405)866-5100",
    },
    {
      icon: Message,
      heading: "Email",
      para: "valleyhospital@gmail.com",
    },
    {
      icon: location,
      heading: "Address",
      para: "Valley Community Hospital,100 Valley Dr, Pauls Valley, OK 73075, United States.",
    },
  ];

  return (
    <div className="contactUs">
      <Card2>
        <div className="section1">
          <div class="section1-left">
            <div
              className="section1-title"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              Contact us
            </div>
            <div
              className="section1-para"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              We take care of your health. Make a quick Consultation. Your
              health is our priority. Our compassionate team of healthcare
              professionals is here to listen, understand, and address your
              needs with utmost sensitivity and expertise. We appreciate your
              trust in us and look forward to serving you in the best possible
              way.
            </div>
            <div
              className="section1-button"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              <a className="realButton" href="#">
                Get In Touch
              </a>
            </div>
          </div>

          <div class="section1-right">
            <div class="internal-container">
              <div class="maindoctor">
                <img src={MainView} alt="mainview" />
              </div>
              <div class=" object object1">
                <div class="object1-container">
                  <div class="tick">
                    <img src={tick} alt="tick" className="icon" />
                  </div>
                  <div class="object-title">Get Expert Guidance Now</div>
                </div>
              </div>

              <div class="object object2">
                <div class="object2-title">Speak with Our Specialists</div>
                <div class="object-content">
                  <div class="contact-icon">
                    <img src={contact} alt="icon" />
                  </div>
                  <div class="phone">(405)866-5100</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card2>

      {/* <div class="section2">
        <img src={Bgsection5} alt="" className="Bgsection5" />
      </div> */}

      <div className="section3">
        <img src={Bgsection5} alt="" className="Bgsection5" />

        <div class="section3-internal">
          <Card2>
            <div
              className="section3-title"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              Connect with Our Team
            </div>
            <div
              className="section3-para"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              Valley Hospitals is always looking to make things easier for you.
              Our aim is to provide our patients with the best medical
              facilities, constant care, and reliable support. If you would like
              to get in touch with a doctor from a specific department, would
              like some specific information about the services we provide, or
              just have a question for us, please fill up the Form given below
              and we will get back to you.
            </div>

            <div
              class="section3-bottom-container"
              // data-aos="fade-up"
              // data-aos-delay="10"
              // data-aos-duration="1000"
            >
              <div class="section3-left-container">
                <div
                  class="contact-doctor"
                  data-aos="fade"
                  data-aos-delay="500"
                  data-aos-duration="2000"
                >
                  <img src={detailsDoc} alt="doc" />
                </div>

                <div class="details-container">
                  {contactDetails.map((each) => (
                    <div
                      class="contact-container"
                      data-aos="fade-up"
                      data-aos-delay="10"
                      data-aos-duration="1000"
                    >
                      <div class="icon">
                        <img src={each.icon} alt="phone" />
                      </div>
                      <div class="content">
                        <div class="title">{each.heading}</div>
                        <div class="number">{each.para}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div class="section3-right-container">
                <form onSubmit={onSubmit}>
                  <InputTextField
                    ref={resetRefs["fullName"]}
                    text="Patient Name"
                    containerClassName="colSpan1"
                    validation={alwaysValidated}
                    charValidate={noCharValidateCheck}
                    error_message={error_message["fullName"]}
                    storeData={storeData.bind(null, "fullName")}
                    // placeholder="Please enter a full name"
                    superText=""
                  />

                  <InputTextField
                    ref={resetRefs["phoneNumber"]}
                    text="Phone Number"
                    containerClassName="colSpan1"
                    validation={alwaysValidated}
                    charValidate={noCharValidateCheck}
                    error_message={error_message["phoneNumber"]}
                    storeData={storeData.bind(null, "phoneNumber")}
                    // placeholder="Please enter a mobile number"
                    superText=""
                  />
                  <InputTextField
                    ref={resetRefs["emailId"]}
                    text="Email Id"
                    containerClassName="colSpan1"
                    validation={alwaysValidated}
                    charValidate={noCharValidateCheck}
                    error_message={error_message["emailId"]}
                    storeData={storeData.bind(null, "emailId")}
                    // placeholder="Please enter a Email Id"
                    superText=""
                  />
                  <InputTextField
                    ref={resetRefs["complaintDoctorName"]}
                    text="Doctor Name"
                    containerClassName="colSpan1"
                    validation={alwaysValidated}
                    charValidate={noCharValidateCheck}
                    error_message={error_message["complaintDoctorName"]}
                    storeData={storeData.bind(null, "complaintDoctorName")}
                    // placeholder="Doctor Name"
                    superText=""
                  />
                  <TextAreaField
                    ref={resetRefs[" query"]}
                    text="Message"
                    containerClassName="colSpan1"
                    validation={alwaysValidated}
                    charValidate={noCharValidateCheck}
                    error_message={error_message[" query"]}
                    storeData={storeData.bind(null, " query")}
                    // placeholder="Doctor Name"
                    superText=""
                  />

                  <div
                    className="submitButtonContainer"
                    data-aos="fade-up"
                    data-aos-delay="10"
                    data-aos-duration="1000"
                  >
                    <button
                      className="submitButton"
                      onClick={onSubmit}
                      type="submit"
                      // disabled={!isVerified}
                    >
                      Submit
                    </button>
                  </div>
                </form>
                {/* <ReCAPTCHA
                  ref={(r) => setCaptchaRef(r)}
                  sitekey="6Lex_GwmAAAAANIOrhM_Ge3uB78izFMhMtumO1lY"
                  onChange={handleRecaptchaChange}
                /> */}
              </div>
            </div>
          </Card2>
        </div>
      </div>

      <div className="section4">
        <Card2>
          <div className="section4-container">
            <div
              className="title"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              Where to find us?
            </div>
            <div
              className="map"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              <Map />
            </div>
          </div>
        </Card2>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;

{
  // <form onSubmit={onSubmit}>
  //   <div className="section2-container2_right">
  //     <InputTextField
  //       ref={resetRefs["fullName"]}
  //       text="Patient Name"
  //       containerClassName="colSpan1"
  //       validation={alwaysValidated}
  //       charValidate={noCharValidateCheck}
  //       error_message={error_message["fullName"]}
  //       storeData={storeData.bind(null, "fullName")}
  //       placeholder="Please enter a full name"
  //       superText=""
  //     />
  //     <InputTextField
  //       ref={resetRefs["phoneNumber"]}
  //       text="phone Number"
  //       containerClassName="colSpan1"
  //       validation={alwaysValidated}
  //       charValidate={noCharValidateCheck}
  //       error_message={error_message["phoneNumber"]}
  //       storeData={storeData.bind(null, "phoneNumber")}
  //       placeholder="Please enter a mobile number"
  //       superText=""
  //     />
  //     <InputTextField
  //       ref={resetRefs["emailId"]}
  //       text="Email Id"
  //       containerClassName="colSpan1"
  //       validation={alwaysValidated}
  //       charValidate={noCharValidateCheck}
  //       error_message={error_message["emailId"]}
  //       storeData={storeData.bind(null, "emailId")}
  //       placeholder="Please enter a Email Id"
  //       superText=""
  //     />
  //     <InputTextField
  //       ref={resetRefs["complaintDoctorName"]}
  //       text="Doctor Name"
  //       containerClassName="colSpan1"
  //       validation={alwaysValidated}
  //       charValidate={noCharValidateCheck}
  //       error_message={error_message["complaintDoctorName"]}
  //       storeData={storeData.bind(null, "complaintDoctorName")}
  //       placeholder="Doctor Name"
  //       superText=""
  //     />
  //     <label htmlFor="text" className="label1">
  //       Message
  //     </label>
  //     <textarea
  //       className="complaint-container"
  //       placeholder="Please enter your query"
  //       id="text"
  //       value={message}
  //       onChange={(e) => setMessage(e.target.value)}
  //     ></textarea>
  //     <div className="submitButtonContainer">
  //       <button
  //         className="submitButton"
  //         onClick={onSubmit}
  //         type="submit"
  //         disabled={!isVerified}
  //       >
  //         Submit
  //       </button>
  //     </div>
  //   </div>
  // </form>;
  //  <ReCAPTCHA
  //         ref={(r) => setCaptchaRef(r)}
  //         sitekey="6Lex_GwmAAAAANIOrhM_Ge3uB78izFMhMtumO1lY"
  //         onChange={handleRecaptchaChange}
  //       />
}
