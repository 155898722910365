import React from "react";
import whitebg from "../images/home/whitebg.png";
import bluebg from "../images/home/bluebg.png";
import "../css/homeImageComponent.scss";

const HomeImageComponent = ({ title, desc, icon, index }) => {
  return (
    <div
      class="homeImage-container"
      data-aos="fade-up"
      data-aos-delay={(index + 1) * 200}
      data-aos-duration="1000"
      // data-aos-once="true"
      data-aos-anchor-placement="center-center"
    >
      <img src={bluebg} alt="" class="bgImg" />
      <div class="contentContanier">
        <div className="title"> {title}</div>
        <div className="centerImg">
          <img src={whitebg} alt="whitebg" className="whitebg" />
          <div class="vctContainer">
            <img src={icon} alt="icon" className="icon" />
          </div>
        </div>
        <div className="content">{desc}</div>
      </div>
    </div>
  );
};

export default HomeImageComponent;
