import React, { useState, useEffect, useReducer } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import NavigationBar from "./components/navigationBar";
import ModalContext from "./context/ModalContext";

import Modal from "./components/Modal";
import ModalApplicationForm from "./components/ModalApplicationForm";
import AdPost from "./components/AdPost";
import AOS from "aos";

import Home from "./pages/Home";
import Doctor from "./pages/Doctor";
import Services from "./pages/Services";
import Careers from "./pages/Careers";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";

import "./App.css";
import "./css/utility.scss";
import "aos/dist/aos.css";

const initalState = {
  modalApplicationForm: false,
  job: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE":
      const newModalApplicationForm = !state.modalApplicationForm;
      return { ...state, modalApplicationForm: newModalApplicationForm };

    case "STORE_JOB":
      return { ...state, job: action.job };
    case "CLOSE_MODAL":
      return { ...state, modalApplicationForm: false };
    case "OPEN_MODAL":
      return { ...state, modalApplicationForm: true };

    default:
      return state;
  }
};

function App() {
  const [showModal, setShowModal] = useState(false);
  const [showAdPost, setAdPost] = useState(false);
  const [state, dispatch] = useReducer(reducer, initalState);

  const onClose = () => {
    setShowModal(false);
  };
  const onCloseAd = () => {
    setAdPost(false);
  };
  useEffect(() => {
    AOS.init({
      startEvent: "DOMContentLoaded",
      throttleDelay: 300,
      // disableMutationObserver: true,
      once: false,
    });
    // window.addEventListener("load", () => {
    //   AOS.init({
    //     startEvent: "DOMContentLoaded",
    //     throttleDelay: 300,
    //     // disableMutationObserver: true,
    //     // once: true,
    //   });
    // });
    // return () => {
    //   AOS.kill();
    // };
  }, []);
  return (
    <div>
      {showModal && <Modal close={onClose} />}
      {showAdPost && <AdPost close={onCloseAd} />}
      <ModalContext.Provider value={{ state, dispatch }}>
        {state.modalApplicationForm && <ModalApplicationForm />}
      </ModalContext.Provider>

      <NavigationBar />
      <ScrollToTop>
        {/* <BrowserRouter> */}
        <Switch>
          <Route path="/" exact>
            <Redirect to="/home" />
          </Route>
          <Route path="/home" exact>
            <Home />
          </Route>
          {/* <Route path="/doctor" exact>
            <Doctor />
          </Route> */}
          <Route path="/services" exact>
            <Services />
          </Route>
          <Route path="/careers" exact>
            <ModalContext.Provider value={{ state, dispatch }}>
              <Careers />
            </ModalContext.Provider>
          </Route>
          <Route path="/aboutUs" exact>
            <AboutUs />
          </Route>
          <Route path="/contactUs" exact>
            <ContactUs />
          </Route>
        </Switch>
        {/* </BrowserRouter> */}
      </ScrollToTop>
    </div>
  );
}

export default App;
