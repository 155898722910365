import { useEffect } from "react";
import "../css/home.scss";
import Card2 from "../components/Card2";
import femaledoc from "../images/home/female_doc.jpg";
import love from "../images/home/love.png";
import smiley from "../images/home/smiley.png";
import call from "../images/home/call.png";
import build from "../images/home/build.png";
import location from "../images/home/location.png";
import bluebg from "../images/home/bluebg.png";
import whitebg from "../images/home/whitebg.png";
import doc1 from "../images/home/doc1.png";
import doc2 from "../images/home/doc2.png";
import drops from "../images/home/drops.png";
import heart from "../images/home/heart.png";
import curve1 from "../images/home/curve1.svg";
import beat from "../images/home/beat.png";
import cardio from "../images/home/cardio.png";
import gastro from "../images/home/gastro.png";
import ortho from "../images/home/ortho.png";
import serviceimage1 from "../images/home/serviceimage1.png";
import serviceimage2 from "../images/home/serviceimage2.png";
import servicebg from "../images/home/servicebg.png";
import HomeImageComponent from "../components/HomeImageComponent";
import testdoc from "../images/home/testdoc.png";
import test1 from "../images/home/test1.png";
import test4 from "../images/home/test2.png";
import test3 from "../images/home/test3.png";
import test2 from "../images/home/test4.png";
import sleep from "../images/home/sleep.png";
import opthal from "../images/home/opthal.png";
import wound from "../images/home/wound.png";
import blog1 from "../images/home/blog1.png";
import blog2 from "../images/home/blog2.png";
import blog3 from "../images/home/blog3.png";
import blog4 from "../images/home/blog4.png";
import blog5 from "../images/home/blog5.png";
import blog6 from "../images/home/blog6.png";
import blog7 from "../images/home/blog7.png";
import calender from "../images/home/Calendar.png";
import doc3 from "../images/home/doc3.png";
import Bgsection5 from "../images/home/background.svg";
import AOS from "aos";
// import Ticker from "../pages/Ticker";
import TitleComponent from "../components/TitleComponent";
import { gsap } from "gsap";
import Footer from "../components/Footer";
const ImgData = [
  {
    title: "Emergency Department",
    desc: "24X7 Accepting Patients",
    icon: call,
  },
  { title: "Contact Us", desc: "10 AM-5PM", icon: build },
  { title: "Location", desc: "Pauls Valley Community", icon: location },
];

const testimonials = [
  {
    icon: test1,
    name: "Mark Davis",
    date: "June 12",
    content:
      "The staff was attentive, knowledgeable, and made me feel supported throughout my recovery. I highly recommend their services. ",
  },
  {
    icon: test2,
    name: "Sarah Johnson  ",
    date: "March 02",
    content:
      "Valley Community Hospital provided me with exceptional care, from the knowledgeable staff to the comfortable facilities. I am grateful for their dedication to my well-being.",
  },
  {
    icon: test3,
    name: "Emma Thompson",
    date: "March 25",
    content:
      "The service at Valley Community Hospital was exceptional. The staff was caring, attentive, and went above and beyond to ensure my comfort. I am grateful for the excellent care I received. ",
  },
  {
    icon: test4,
    name: "Alex M",
    date: "Feb 25",
    content:
      "Valley Community Hospital exceeded my expectations. The staff was exceptional, providing attentive care and making me feel comfortable throughout my treatment.",
  },
];

const Home = () => {
  console.log("In Home page");

  const objectData = [
    {
      number: "262K+",
      image: smiley,
      para: "Recovered Patients",
    },
    {
      number: "96%",
      image: love,
      para: "Satisfaction Rate",
    },
  ];

  const blogData = [
    {
      img: blog5,
      icon: calender,
      date: "may 24",
      content:
        "Emergency Department of  Pauls Valley Community Hospital is now fully operational",
      link: "Read more",
    },
    {
      img: blog6,
      icon: calender,
      date: "may 29",
      content:
        "RT-PCR test is mandatory for people travelling to different countries",
      link: "Read more",
    },
    {
      img: blog7,
      icon: "",
      date: "",
      content:
        "New management has taken over SPGMC and promises better care and support to the community",
      link: "Read more",
    },
  ];
  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      setTimeout(function () {
        AOS.refresh();
      }, 5000);
    });
    return () => {
      document.removeEventListener("DOMContentLoaded", null);
    };
  }, []);
  return (
    <div className="home">
      <Card2>
        <div className="section1">
          <div className="content">
            <div className="section1-title" data-aos="fade-up">
              Your Community, <br />
              Your Health Care
            </div>
            <div className="section1-para" data-aos="fade-up">
              Valley Community Hospital is committed to provide utmost care to
              the people of Pauls Valley. Our 43 bed facility is now fully open
              and ready to serve your community.
            </div>
            <div
              className="section1-button"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              <a className="realButton" href="./ContactUs">
                Visit Us
              </a>
            </div>
          </div>
          <div className="right-content">
            <div className="internalContainer">
              <div className="femaledoc">
                <img src={femaledoc} alt="femaledoc" />
              </div>

              <div className="object-Container object1">
                <div className="title">
                  <img src={smiley} alt="smiley" className="smiley" />
                  <div className="rating">216K+</div>
                </div>
                <div className="object-content">Recovered Patients</div>
              </div>
              <div className="object-Container object2">
                <div className="title">
                  <img src={love} alt="smiley" className="love" />
                  <div className="rating">96%</div>
                </div>
                <div className="object-content">Satisfaction Rate</div>
              </div>
            </div>
          </div>
        </div>
      </Card2>

      <Card2>
        <div className="section2">
          <div className="image-container">
            {ImgData.map((el, index) => (
              <HomeImageComponent {...el} index={index} />
            ))}
          </div>
        </div>
      </Card2>

      <Card2>
        <div className="section3">
          <div className="internal-container">
            <div className="images-Drop-Container">
              <div
                className="doc1"
                data-aos="fade"
                data-aos-easing="ease-in-out"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-anchor=".images-Drop-Container"
                data-aos-anchor-placement="center-bottom"
              >
                <img src={doc1} alt="doc1" />
              </div>
              <div
                className="doc2"
                data-aos="fade"
                // data-aos="doc2-animation"
                data-aos-easing="ease-in-out"
                // data-aos-once="false"
                data-aos-delay="300"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-anchor=".images-Drop-Container"
                data-aos-anchor-placement="center-bottom"
              >
                <img src={doc2} alt="doc2" />
              </div>
              <div
                className="drop"
                data-aos="fade"
                data-aos-easing="ease-in-out"
                data-aos-delay="600"
                data-aos-offset="100"
                data-aos-duration="1000"
                data-aos-anchor=".images-Drop-Container"
                data-aos-anchor-placement="center-bottom"
              >
                <img src={drops} alt="drop" />
              </div>
            </div>
          </div>
          <div className="content-container">
            <div
              className="heart-container"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              <img src={heart} alt="heart" className="heart" />
            </div>
            <TitleComponent title={"About Us"} />

            <div
              className="section3-Heading"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              Valley Community Hospital
            </div>
            <div
              className="section3-para"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              Valley Community Hospital is a best -in-class hospital for the
              paul’s Valley, so they never have to leave their own community to
              received the highest level of patient care they deserve. We have
              specifically handpicked the best of specialty doctors to create a
              more efficient health system for the community.
            </div>
          </div>
        </div>
      </Card2>

      <Card2>
        <div className="section4">
          <div className="section4-maincontainer">
            <div className="section4-designElement1">
              <TitleComponent title={" Our Services"} />

              <div
                className="section4-heading"
                data-aos="fade-up"
                data-aos-delay="10"
                data-aos-duration="1000"
              >
                Highest Quality
                <br /> Services for you.
              </div>
              <div
                className="section4-para"
                data-aos="fade-up"
                data-aos-delay="10"
                data-aos-duration="1000"
              >
                Valley Community Hospital see no compromise when it comes to the
                quality of its services. We assure the people of our community
                to receive the most efficient care from highly experienced and
                compassionate providers.
              </div>
            </div>

            <div className="section4-designElement2">
              <div className="internal-container">
                {/* <div className="internal-container1"> */}
                <div
                  className="doc3-image"
                  data-aos="fade"
                  data-aos-delay="500"
                  data-aos-duration="2000"
                >
                  <img src={doc3} alt="doc3" className="doc3" />
                </div>
                <div className="service service1">
                  <img src={sleep} alt="sleep" className="service-img" />
                  <div className="service-content">Sleep Studies</div>
                </div>
                <div className="service service2">
                  <img src={gastro} alt="gastro" className="service-img" />
                  <div className="service-content">Gastroenterology</div>
                </div>
                <div className="service service3">
                  <img src={opthal} alt="opthal" className="service-img" />
                  <div className="service-content">Ophthalmology</div>
                </div>
                <div className="service service4">
                  <img src={ortho} alt="ortho" className="service-img" />
                  <div className="service-content">Orthopedics</div>
                </div>
                <div className="service service5">
                  <img src={cardio} alt="cardio" className="service-img" />
                  <div className="service-content">Cardiology</div>
                </div>
                <div className="service service6">
                  <img src={wound} alt="wound" className="service-img" />
                  <div className="service-content">Wound Care </div>
                </div>
                <div
                  className="beat"
                  data-aos="beat-animation"
                  data-aos-easing="ease-in-out"
                  // data-aos-once="false"
                  data-aos-offset="10"
                  data-aos-duration="2000"
                  // data-aos-delay="500"
                >
                  <img src={beat} alt="beat" />
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </Card2>

      <div
        className="section6"
        data-aos="fade-up"
        data-aos-delay="10"
        data-aos-duration="1000"
      >
        <img src={Bgsection5} alt="" className="Bgsection5" />
        <div className="section6-container">
          <div className="mainTitle" data-aos="fade-up">
            Why Choose VCH ?
          </div>
          <div className="desc" data-aos="fade-up">
            Valley Community Hospital is proud to say our providers work
            tirelessly with staffs to provide the best possible care. We are
            rapdily expanding our services to meet all the healthcare needs of
            the community.
          </div>

          <div className="cardContainer">
            <div
              className="cardElement"
              data-aos="fade"
              data-aos-delay="100"
              data-aos-duration="2000"
            >
              <div className="cardIcon">
                <img src={blog1} alt="" />
              </div>
              <div className="cardTitle">
                <div>24x7 </div>
                <div>Emergency Care</div>
              </div>
              <div className="cardContent">
                Help your loved ones get well sooner with our 24x7 Immediate
                Care
              </div>
            </div>
            <div
              className="cardElement"
              data-aos="fade"
              data-aos-delay="400"
              data-aos-duration="2000"
            >
              <div className="cardIcon">
                <img src={blog2} alt="" />
              </div>
              <div className="cardTitle">
                <div>Expert Providers</div>
                <div className="hideVisibility">dummy</div>
              </div>
              <div className="cardContent">
                Highly trained and experienced physicians available at all times
              </div>
            </div>
            <div
              className="cardElement"
              data-aos="fade"
              data-aos-delay="800"
              data-aos-duration="2000"
            >
              <div className="cardIcon">
                <img src={blog3} alt="" />
              </div>
              <div className="cardTitle">
                <div>Radiology</div>
                <div className="hideVisibility">dummy </div>
              </div>
              <div className="cardContent">
                Most sophisticated department offering quick and precise results
              </div>
            </div>
            <div
              className="cardElement"
              data-aos="fade"
              data-aos-delay="1000"
              data-aos-duration="2000"
            >
              <div className="cardIcon">
                <img src={blog4} alt="" />
              </div>
              <div className="cardTitle">
                <div>Wound Care</div>
                <div className="hideVisibility">dummy </div>
              </div>
              <div className="cardContent">
                Advanced Wound Care program to acheive timely wound healing.
              </div>
            </div>
          </div>
        </div>
      </div>

      <Card2>
        <div className="section7">
          <div class="serviceimage-container">
            <img
              src={serviceimage1}
              alt="service1"
              className="service1"
              data-aos="fade"
              data-aos-delay="400"
              data-aos-duration="1500"
              data-aos-anchor=".serviceimage-container"
              data-aos-anchor-placement="top-center"
            />
            <img
              src={serviceimage2}
              alt="service2"
              className="service2"
              data-aos="fade"
              data-aos-delay="800"
              data-aos-duration="1500"
              data-aos-anchor=".serviceimage-container"
              data-aos-anchor-placement="top-center"
            />
            <img
              src={servicebg}
              alt="servicebg"
              className="servicebg"
              data-aos="fade"
              data-aos-delay="100"
              data-aos-duration="1500"
              data-aos-anchor=".serviceimage-container"
              data-aos-anchor-placement="top-center"
            />
          </div>

          <div class="section7-content">
            <div className="curve1" data-aos="fade-up">
              <img src={curve1} alt="service1" />
            </div>
            <TitleComponent title={" Our Services"} />

            <div
              className="section7-heading"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              You Come First
            </div>
            <div
              class="section7-para"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              Treatment at Valley Community Hospital is truly human experience
              and you are cared for like a family member. Our diligent providers
              and staff strive to bestow exceptional care, laboratory services
              and many more.
            </div>
          </div>
        </div>
      </Card2>

      <Card2>
        <div className="section8">
          <TitleComponent title={" Testimonials"} />

          <div
            class="section8-heading"
            data-aos="fade-up"
            data-aos-delay="10"
            data-aos-duration="1000"
          >
            What Our Patients Say
          </div>
          <div class="testimonial-image-container">
            <div class="testimonial-internal-container">
              <div class="testimonial-doc">
                <img
                  src={testdoc}
                  alt="testdoc"
                  className="testdoc"
                  data-aos="fade"
                  data-aos-delay="400"
                  data-aos-duration="2000"
                />

                <div
                  class="test-obj test-obj1"
                  data-aos="flip-left"
                  data-aos-delay="600"
                  data-aos-duration="1000"
                >
                  <div class="test-obj-title">
                    <div class="test-obj-icon">
                      <img src={test1} alt="test1" className="testimg" />
                    </div>
                    <div class="test-obj-heading-container">
                      <div class="test-obj-heading">Mark Davis </div>
                      <div class="test-obj-para">June 12 </div>
                    </div>
                  </div>
                  <div class="test-obj-content">
                    The staff was attentive, knowledgeable, and made me feel
                    supported throughout my recovery. I highly recommend their
                    services.
                  </div>
                </div>

                <div
                  class="test-obj test-obj2"
                  data-aos="flip-right"
                  data-aos-delay="1000"
                  data-aos-duration="1000"
                >
                  <div class="test-obj-title">
                    <div class="test-obj-icon">
                      <img src={test2} alt="test2" className="testimg" />
                    </div>
                    <div class="test-obj-heading-container">
                      <div class="test-obj-heading">Sarah Johnson </div>
                      <div class="test-obj-para">March 02 </div>
                    </div>
                  </div>
                  <div class="test-obj-content">
                    Valley Community Hospital provided me with exceptional care,
                    from the knowledgeable staff to the comfortable facilities.
                    I am grateful for their dedication to my well-being.
                  </div>
                </div>

                <div
                  class="test-obj test-obj3"
                  data-aos="flip-left"
                  data-aos-delay="600"
                  data-aos-duration="1000"
                >
                  <div class="test-obj-title">
                    <div class="test-obj-icon">
                      <img src={test3} alt="test3" className="testimg" />
                    </div>
                    <div class="test-obj-heading-container">
                      <div class="test-obj-heading">Emma Thompson </div>
                      <div class="test-obj-para">March 25</div>
                    </div>
                  </div>
                  <div class="test-obj-content">
                    The service at Valley Community Hospital was exceptional.
                    The staff was caring, attentive, and went above and beyond
                    to ensure my comfort. I am grateful for the excellent care I
                    received.
                  </div>
                </div>

                <div
                  class="test-obj test-obj4"
                  data-aos="flip-right"
                  data-aos-delay="1000"
                  data-aos-duration="1000"
                >
                  <div class="test-obj-title">
                    <div class="test-obj-icon">
                      <img src={test4} alt="test4" className="testimg" />
                    </div>
                    <div class="test-obj-heading-container">
                      <div class="test-obj-heading">Alex M </div>
                      <div class="test-obj-para">Feb 25</div>
                    </div>
                  </div>
                  <div class="test-obj-content">
                    Valley Community Hospital exceeded my expectations. The
                    staff was exceptional, providing attentive care and making
                    me feel comfortable throughout my treatment.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card2>

      <Card2>
        <div class="section9">
          <TitleComponent title={"  Blog"} />

          <div
            class="section9-heading"
            data-aos="fade-up"
            data-aos-delay="10"
            data-aos-duration="1000"
          >
            Latest News & Events
          </div>

          <div
            class="blog-Main-container"
            // data-aos="fade-up"
            // data-aos-delay={(index + 1) * 200}
            // data-aos-duration="1000"
          >
            {blogData.map((each, index) => (
              <div
                class="blog-container"
                data-aos="fade-up"
                data-aos-delay={(index + 1) * 200}
                data-aos-duration="1000"
              >
                <div class="blog-image">
                  <img src={each.img} alt="blog-image" />
                </div>
                <div class="blog-content">
                  <div class="blog-title">
                    <div class="icon">
                      <img src={each.icon} />
                    </div>
                    <div class="date">{each.date}</div>
                  </div>

                  <div class="para">{each.content}</div>

                  <div className="read-link">
                    <a href="#" className="read-more">
                      {each.link}
                    </a>
                  </div>
                </div>
              </div>
            ))}
            {/* <div class="blog-container">
              <div class="blog-image">
                <img src={img} alt="blog-image" />
              </div>
              <div class="blog-content">
                <div class="blog-title">
                  <div class="icon">
                    <img src={icon} alt="calender" />
                  </div>
                  <div class="date">{date}</div>
                </div>

                <div class="para">{content}</div>

                <div className="read-link">
                  <a href="#" className="read-more">
                    {link}
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Card2>

      <Footer />
    </div>
  );
};

export default Home;
