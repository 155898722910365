import React, { useState, useEffect } from "react";
import { useContext } from "react";
import "../css/FAQ_QA.scss";

import Plus from "../images/FAQ/plus.png";
import Minus from "../images/FAQ/minus.png";
import location from "../images/FAQ/Location.png";
import work from "../images/FAQ/Work.png";

import classnames from "classnames";
import AOS from "aos";
import ModalContext from "../context/ModalContext";
import "aos/dist/aos.css";
// import { type } from "@testing-library/user-event/dist/type";

const FAQ_QA = ({ question, shortDesc, detailDesc }) => {
  const [show, toShow] = useState(false);
  const onButtonClick = () => {
    console.log("Triggering switch state to", !show);
    toShow((prevState) => !prevState);
  };
  const { state, dispatch } = useContext(ModalContext);

  useEffect(() => {
    AOS.init({
      startEvent: "DOMContentLoaded",
    });
  }, []);

  const onApplyNowClick = async (e) => {
    console.log(question.position);
    dispatch({ type: "STORE_JOB", job: question.position });
    dispatch({ type: "OPEN_MODAL" });

    // const FORMSPARK_ACTION_URL = "https://submit-form.com/7V5O4RXD";
    // e.preventDefault();

    // // if (isVerified != "") {
    // // console.log(formData);
    // await fetch(FORMSPARK_ACTION_URL, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    //   body: JSON.stringify({
    //     name: inputData.fullName,
    //     email: inputData.emailId,
    //     doctorname: inputData.complaintDoctorName,
    //     message: inputData.query,
    //     g_recaptcha_response: isVerified,
    //     phonenumber: inputData.phoneNumber,
    //   }),
    // });
    // alert("Form submitted");
    // console.log("Form submitted successfully!");
    // Object.keys(resetRefs).forEach((value) => {
    //   resetRefs[value].current.reset_data();
    // });
  };
  return (
    <div
      className="FAQ_QA"
      data-aos="fade-up"
      data-aos-delay="200"
      data-aos-duration="1000"
    >
      {/* <div data-aos="fade-up" data-aos-delay="200" className="FAQ_QA"> */}
      <div className="question_container">
        <div className="question">
          <div className="question_row1">{question.position}</div>
          {/* <div className="question_row2">
            <div className="dep">
              <div className="workIon">
                <img src={work} className="work" alt="work" />
              </div>
              <div className="department"> {question.dept}</div>
            </div>
            <div className="places">
              <div className="locIon">
                <img src={location} className="loc" alt="loc" />
              </div>
              <div className="place">{question.place}</div>
            </div>
          </div> */}
        </div>
        <button className="showHideImg" onClick={onButtonClick}>
          <div className="imgDiv">
            <img src={show ? Minus : Plus} alt="" />
          </div>
        </button>
      </div>
      <div className="question_row2">
        <div className="dep">
          <div className="workIon">
            <img src={work} className="work" alt="work" />
          </div>
          <div className="department"> {question.dept}</div>
        </div>
        <div className="places">
          <div className="locIon">
            <img src={location} className="loc" alt="loc" />
          </div>
          <div className="place">{question.place}</div>
        </div>
      </div>
      <div className={classnames("answer", { hide: !show })}>
        <div className="jobContainer">
          <div className="title">Job Description</div>
          <div className="shortDesc">{shortDesc}</div>
          <div className="detailDesc">
            {detailDesc.map((detail) => {
              let returnData;
              if (typeof detail.details == "string") {
                returnData = (
                  <div className="detailDescElement">
                    <div class="detailDescString">
                      <div className="titleElement">{detail.title} :</div>
                      <div className="detailElement">{detail.details} </div>
                    </div>
                    {/* {detail.title} : {detail.details} */}
                  </div>
                );
              } else {
                returnData = (
                  <div className="detailDescElement">
                    <div className="titleElement">{detail.title} :</div>
                    <ul className="listContainer">
                      {detail.details.map((indi_answer) => (
                        <li>{indi_answer}</li>
                      ))}
                    </ul>
                  </div>
                );
              }
              return returnData;
            })}
          </div>
        </div>
      </div>
      <button className="apply_now" onClick={onApplyNowClick}>
        APPLY NOW
      </button>
    </div>
  );
};

export default FAQ_QA;
