import { useMemo, useState } from "react";
import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
// import GoogleMapReact from 'google-map-react';
// import {  } from "react-google-maps";

export default function Home() {

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyCzoI8jR4nWtLa8vY05HiQISoEi3uKV6KY",
    });

    if (!isLoaded) return <div>Loading...</div>;
    return <Map />;
}

function Map() {
    const [mapInstance, setMapInstance] = useState(false)
    const center = useMemo(() => ({ lat: 34.7193615, lng: -97.2145011 }), []);

    return (
        <GoogleMap zoom={16} center={center} mapContainerClassName="map-container" onLoad={(map) => setTimeout(() => setMapInstance(map))}>
            {mapInstance && (
                <Marker
                    position={center}

                />
            )}


        </GoogleMap>
    );
}

// const Marker = ({ text }) => <div>{text}</div>;

// export default function SimpleMap(){
//   const defaultProps = {
//     position: {
//       lat:  34.7193615,
//       lng: -97.2145011
//     },
//     zoom: 16
//   };

//   return (
//     // Important! Always set the container height explicitly
//     <div style={{ height: '75vh', width: '100%' }}>
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: "AIzaSyCzoI8jR4nWtLa8vY05HiQISoEi3uKV6KY" }}
//         defaultCenter={defaultProps.position}
//         defaultZoom={defaultProps.zoom}
//       >
//         <Marker 
//           text="My Marker"
//         />
//       </GoogleMapReact>
//     </div>
//   );
// }