import { useContext, useRef, useState, useCallback, useEffect } from "react";
import ModalContext from "../context/ModalContext";
import BackDrop2 from "./BackDrop2";
import axios from "axios";
import InputTextField from "../components/InputTextField";
import FileInputField from "../components/FileInputField";
import CharData from "../data_json/CharData.json";

import "../css/modalApplicationForm.scss";

const ModalApplicationForm = () => {
  const { state, dispatch } = useContext(ModalContext);
  const [valid, setValid] = useState(false);
  const send_mail = async (rows) => {
    try {
      console.log(rows.resume[0].name);
      const res = await axios.put(
        // "https://valleypr.herokuapp.com/api/form/resumemail",

        "https://submit-form.com/bb7h71hR",
        {
          name: rows.name,
          phNo: rows.phNo,
          mailId: rows.mailId,
          job_selected: rows.job_selected,
          resume: rows.resume,
        }
      );
      // console.log(res.data);
      // + this.context.user.username
      alert("success we have recevied your application");
    } catch (err) {
      console.log(err);
      alert("error application not sent");
    }
  };
  const submitHandler = () => {
    console.log("Send data ", inputData);
    send_mail(inputData);
    dispatch({ type: "CLOSE_MODAL" });
  };

  const cancelHandler = () => {
    dispatch({ type: "CLOSE_MODAL" });
  };

  const storeData = useCallback((fieldName, value, validityStatus) => {
    // console.log(fieldName, value, validityStatus);
    setInputData((prevState) => {
      // console.log(prevState);
      return { ...prevState, [fieldName]: value };
    });
    if (inputDataValidity.hasOwnProperty(fieldName)) {
      setInputDataValidity((prevState) => ({
        ...prevState,
        [fieldName]: validityStatus,
      }));
    }
  }, []);

  const error_message = {
    name: "Above field should not be empty",
    phNo: "Phone no should contain 10 digits",
    mailId: "Please enter a valid email address",
  };

  const resetRefs = {
    name: useRef(null),
    phNo: useRef(null),
    mailId: useRef(null),
    resume: useRef(null),
  };

  const [inputData, setInputData] = useState({
    name: "",
    phNo: "",
    mailId: "",
    resume: [],
    job_selected: state.job,
  });

  const [inputDataValidity, setInputDataValidity] = useState({
    name: false,
    phNo: false,
    mailId: false,
    resume: false,
  });

  useEffect(() => {
    var temp = true;
    Object.values(inputDataValidity).forEach((value) => {
      temp = temp && value;
    });
    setValid(temp);
  }, [inputDataValidity]);

  const isNotEmpty = (value) => {
    return value.trim() !== "";
  };
  const phNoValidity = (value) => {
    return value.length === 10;
  };
  const mailIdValidity = (value) => {
    return value.includes(".") && value.includes("@");
  };
  const alphaValidate = (charValue) => {
    const allowedValues = [
      ...CharData.smallAlpha,
      ...CharData.capsAlpha,
      " ",
      null,
    ];
    if (allowedValues.includes(charValue)) {
      return true;
    }
    return false;
  };
  const numericValidate = (charValue) => {
    const allowedValues = [...CharData.numerical, null];
    if (allowedValues.includes(charValue)) {
      return true;
    }
    return false;
  };
  const noCharValidateCheck = (charValue) => {
    return true;
  };
  const fileValidateCheck = (files) => {
    const individualFileMaxSize = 2; //sizes in MB
    const totalFileMaxSize = 1; //sizes in MB
    let cumFileSize = 0;

    if (files.length === 0) {
      return [false, ""];
    }
    for (let i in files) {
      console.log(files[i]);
      if (files[i].size > individualFileMaxSize) {
        return [false, `File size exceeds ${individualFileMaxSize}MB`];
      }
      cumFileSize = cumFileSize + files[i].size;
    }
    if (cumFileSize > totalFileMaxSize) {
      return [
        false,
        `Total cumulative file size exceeds ${totalFileMaxSize}MB`,
      ];
    }
    console.log(files);
    return [true, ""];
  };
  return (
    <BackDrop2>
      <div className="modalApplicationForm">
        <div className="job">{state.job} Application</div>
        <div className="mainContainer">
          <InputTextField
            ref={resetRefs["name"]}
            text="Name"
            validation={isNotEmpty}
            charValidate={noCharValidateCheck}
            error_message={error_message["name"]}
            storeData={storeData.bind(null, "name")}
            superText=""
          />
          <InputTextField
            ref={resetRefs["phNo"]}
            text="Phone no"
            validation={phNoValidity}
            charValidate={numericValidate}
            error_message={error_message["phNo"]}
            storeData={storeData.bind(null, "phNo")}
            superText=""
          />
          <InputTextField
            ref={resetRefs["mailId"]}
            text="Email"
            validation={mailIdValidity}
            charValidate={noCharValidateCheck}
            error_message={error_message["mailId"]}
            storeData={storeData.bind(null, "mailId")}
            superText=""
          />
          <FileInputField
            accept="application/pdf"
            validation={fileValidateCheck}
            // multiple={true}
            storeData={storeData.bind(null, "resume")}
          />
        </div>
        <div className="disclaimer">
          Full application will be emailed to qualified applicants.
        </div>

        <div className="submit_container">
          <button
            className="button submit"
            disabled={!valid}
            onClick={submitHandler}
          >
            SUBMIT
          </button>
          <button className="button cancel" onClick={cancelHandler}>
            CANCEL
          </button>
        </div>
      </div>
      ;
    </BackDrop2>
  );
};

export default ModalApplicationForm;
