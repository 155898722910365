import React from "react";
import "../css/title.scss";
const TitleComponent = ({ title }) => {
  return (
    <div
      className="section-title"
      data-aos="fade-up"
      data-aos-delay="10"
      data-aos-duration="1000"
    >
      {title}
    </div>
  );
};

export default TitleComponent;
