import "../css/backdrop2.scss";

const BackDrop2 = ({ children, on = true }) => {
  function preventScroll(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("Scrolling");

    return false;
  }
  return (
    <div className={on ? "backdrop2" : ""} onScroll={preventScroll}>
      {children}
    </div>
  );
};

export default BackDrop2;
