import BackDrop2 from "./BackDrop2";
import "../css/modal.scss";
import WeAreHiring from "../images/common/patience.jpg";
import CloseButton from "../images/common/xmark.svg";
import { Link } from "react-router-dom";

const Modal = ({ close }) => {
  return (
    <BackDrop2>
      <div className="modal">
        <div className="section1">
          <img src={CloseButton} onClick={close} />
        </div>
        <div className="section2">
          <div className="section2_left">
            <div className="section2_left_container1">
              <div className="section2_left_p1">
                Sorry for the inconvenience
              </div>
              {/* <div className="section2_left_p1">Valley Job Fair</div> */}
            </div>
            <div className="section2_left_container2">
              <div className="section2_left_p2">
                We are working hard to quickly rectify issues with our last
                billing cycle.
              </div>
              <div className="section2_left_p2">
                Sincerely apologize for any inconvenience this may have caused.!
              </div>
            </div>
            <div className="section2_left_container3">
              {/* <div class="buttons"><Link to='/careers'><button class="btn-hover color-9" onClick={close}>Learn more!</button> </Link></div> */}
            </div>
          </div>
          <div className="section2_right">
            <img src={WeAreHiring} alt="" />
          </div>
        </div>
      </div>
    </BackDrop2>
  );
};
export default Modal;
