import { useState, useReducer } from "react";

const initialInputState = {
  value: [],
  boxContent: undefined,
};

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT") {
    return {
      value: action.value,
      boxContent: action.name,
    };
  }
  if (action.type === "BLUR") {
    return { value: state.value };
  }
  if (action.type === "RESET") {
    return { value: [], boxContent: undefined };
  }
  return initialInputState;
};

const useFileInput = (validateValue) => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState
  );

  const [valueIsValid, errorDesc] = validateValue(inputState.value);
  const hasError = !valueIsValid && initialInputState.value.length === 0;

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const valueChangeHandler = async (event) => {
    let fileInfo = [],
      files = event.target.files;
    console.log(typeof files, files, Object.keys(files));

    for (const file of Object.values(files)) {
      let temp;
      console.log(` ${file}`);
      temp = await toBase64(file);
      fileInfo.push({
        name: file.name,
        type: file.type,
        size: file.size / 1000000,
        base64: temp,
        file: file,
      });
    }
    console.log(fileInfo);

    dispatch({
      type: "INPUT",
      value: fileInfo,
      name:
        fileInfo.length > 1 ? `${fileInfo.length} Files` : fileInfo[0]["name"],
    });
  };
  const inputBlurHandler = () => {
    dispatch({ type: "BLUR" });
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };

  return {
    value: inputState.value,
    boxContent: inputState.boxContent,
    isValid: valueIsValid,
    errorDesc,
    hasError,
    valueChangeHandler,
    inputBlurHandler,
    reset,
  };
};

export default useFileInput;
