import { NavLink } from "react-router-dom";

const NavList = ({ toToggle, from }) => {
  const onNavigate = () => {
    if (from === "header") {
      toToggle();
    }
  };
  return (
    <>
      <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/home"
        className="elements"
      >
        Home
      </NavLink>
      {/* <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/doctor"
        className="elements"
      >
        Doctor
      </NavLink> */}
      <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/services"
        className="elements"
      >
        Services
      </NavLink>
      <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/careers"
        className="elements"
      >
        Careers
      </NavLink>
      <NavLink
        onClick={onNavigate}
        className="elements"
        to={{
          pathname:
            "https://southernplainsmedicalcenter.pg.quadax.revenuemasters.com/",
        }}
        target="_blank"
      >
        Payer Pricing
      </NavLink>
      {/* <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/aboutUs"
        className="elements pushLeft bg-gray "
      >
        About Us
      </NavLink> */}
      <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/contactUs"
        className="elements"
      >
        (405)866-5100 &nbsp; | &nbsp; Contact Us
      </NavLink>
    </>
  );
};

export default NavList;
