import DrawLine from "../components/DrawLine";
import "../css/services.scss";
import slide1 from "../images/services/slide1.png";
import slide2 from "../images/services/slide2.png";
import slide3 from "../images/services/slide3.png";
import slide4 from "../images/services/slide4.png";
import slide5 from "../images/services/slide5.png";
import slide6 from "../images/services/slide6.png";
import slide7 from "../images/services/slide7.png";
import obj1 from "../images/services/obj1.png";
import obj2 from "../images/services/obj2.png";
import obj3 from "../images/services/obj3.png";
import doc1 from "../images/services/consultdoc1.png";
import doc2 from "../images/services/consultdoc2.png";
import doc3 from "../images/services/consultdoc3.png";
import curve1 from "../images/services/curve1.png";
import curve3 from "../images/services/curve3.svg";
import blueray from "../images/services/blueray.svg";
import curve4 from "../images/services/curve4.svg";
import blue from "../images/services/blue.png";
import add from "../images/services/add.png";
import redheart from "../images/services/redheart.png";
import maindoc from "../images/services/maindoc.png";
import Card2 from "../components/Card2";
import Logo from "../images/common/logomain.png";
import tick from "../images/services/tick.png";

import { useEffect } from "react";
import Footer from "../components/Footer";

import Carousel from "../components/Carousel";
import blueheart from "../images/services/blueheart.png";
import maledoc from "../images/services/maledoc.png";

const services_data = [
  {
    title: [{ line1: "Emergency Room" }, { line2: "" }],
    desc: [
      {
        para: "In the ER, our caring and specialized team of doctors and nurses will",
      },
      {
        para: " provide you with the highest level of patient care. Whether you need X-",
      },
      {
        para: "rays, CT scans, blood work, or other tests, you can rest assured",
      },
      {
        para: " knowing our team of professionals will quickly and effectively get",
      },
      {
        para: "you the attention and care you need to get you healthy and back on",
      },
      { para: "your feet." },
      { para: "" },
    ],
    image: slide1,
  },
  {
    title: [{ line1: "Sleep Studies" }, { line2: "" }],
    desc: [
      { para: "Our experts are able to perform in-home sleep studies to help" },
      { para: "diagnose and treat sleep disorders, including polysomnography" },
      {
        para: "which records your brain waves, the oxygen level in your blood,",
      },
      {
        para: " heart rate, and breathing, as well as eye and leg movements to help",
      },
      {
        para: "diagnose any potential sleep disorders. In-lab sleep studies will be",
      },
      { para: "offered to our patients in the near future." },
      { para: "" },
    ],
    image: slide2,
  },
  {
    title: [{ line1: "Pain Management" }, { line2: " " }],
    desc: [
      {
        para: "Valley Community Hospital patients can expect exceptional care for",
      },
      {
        para: " chronic pain conditions from our interventional pain management",
      },
      { para: "physicians. Our pain management team utilizes a variety of" },
      {
        para: " procedures such as nerve blocks, injections, back pain, or anything in",
      },
      {
        para: "between, Our team of providers will help you manage, reduce and ",
      },
      {
        para: "relieve your pain so you can get back to the things that matter most",
      },
      { para: "in life." },
    ],
    image: slide3,
  },
  {
    title: [{ line1: "Orthopedic" }, { line2: "" }],

    desc: [
      {
        para: "Our highly respected orthopedic specialists are committed to the ",
      },
      {
        para: " treatment of musculoskeletal issues and work diligently with patients",
      },
      {
        para: "on their orthopedic needs. Whether you need care for injuries and",
      },
      {
        para: " disease that affects bones, joins, ligaments, tendons, muscles, or",
      },
      {
        para: "nerves, our providers are dedicated to restoring and mobility while ",
      },
      { para: "tailoring a care plan specific to each patient’s needs." },
      { para: "" },
    ],
    image: slide4,
  },
  {
    title: [{ line1: "Cardiovascular" }, { line2: "" }],

    desc: [
      {
        para: "Cardiology is a field of medicine that specialize in conditions of the  ",
      },
      {
        para: " heart and other part of the cardiovascular system. Our team of ",
      },
      {
        para: "specialized providers can diagnose and treat a wide variety of heart",
      },
      {
        para: " condition and vascular system issues while helping you manage and",
      },
      {
        para: "prevent further cardiovascular related complications in the future. ",
      },
      { para: "" },
      { para: "" },
    ],
    image: slide5,
  },
  {
    title: [
      { line1: "General Surgery (gallbladder," },
      { line2: " appendectomy, egd, colonoscopy, etc.)" },
    ],
    desc: [
      {
        para: "Valley community Hospital general surgery department is equipped ",
      },
      {
        para: " and ready to aid in more routine procedures that focus on the",
      },
      {
        para: "stomach, small bowel, colon, liver, pancreas, and gallbladder. This",
      },
      {
        para: " means we can provide the highest level of care to patients who find",
      },
      { para: "themselves needing procedures such  as a gallbladder removal " },
      { para: "appendectomy, EGD, colonoscopy, and more." },
      { para: "" },
    ],
    image: slide6,
  },
  {
    title: [{ line1: "Family practice and home visits" }, { line2: "" }],

    desc: [
      {
        para: "Our team of family practice doctor and providers provider care to  ",
      },
      {
        para: " people of all ages! These generalists treat Chronic conditions, offer",
      },
      {
        para: "preventative care, evaluate symptoms, and let people know when ",
      },
      {
        para: "they need to see a specialist for additional care and support. Our ",
      },
      {
        para: "team of family practice providers are some of the best in the state,  ",
      },
      { para: "so be sure to book your next appointment with us today" },
      { para: "" },
    ],
    image: slide7,
  },
];

const images = [
  {
    imageurl: obj1,
  },
  {
    imageurl: obj2,
  },
  {
    imageurl: obj3,
  },
  {
    imageurl: add,
  },
];

const Services = () => {
  console.log("In Services page");
  // useEffect(() => {
  //   AOS.init({
  //     startEvent: "DOMContentLoaded",
  //   });
  // }, []);
  return (
    <div className="services">
      <Card2>
        <div className="section1">
          <div className="content">
            <div
              className="section1-heading"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              Your health is our priority.
            </div>
            <div
              className="section1-para"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              We are dedicated to delivering high-quality healthcare services
              that prioritize your well-being and safety.
            </div>
            <div
              className="section1-button"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              <a className="realButton">Meet Our Specialist</a>
            </div>
          </div>
          <div className="doctor-image">
            <div className="internalContainer">
              <img src={maindoc} alt="maindoc" />
              <div
                className="regular-checkup"
                data-aos="fade-right"
                data-aos-delay="10"
                data-aos-duration="1000"
              >
                <img src={tick} alt="tick" />
                <p className="">Regular Checkup</p>
              </div>

              <div
                className="doctoDesignElement"
                data-aos="fade-up"
                data-aos-delay="10"
                data-aos-duration="1000"
              >
                <div className="redheart">
                  <img src={redheart} alt="redheart" />
                </div>
                <div className="circle"></div>
              </div>

              <div
                className="object-container"
                data-aos="fade-left"
                data-aos-delay="10"
                data-aos-duration="1000"
              >
                <div className="object-heading">Our specialist</div>
                <div className="image-container">
                  {images.map((each) => (
                    <img src={each.imageurl} alt="images" />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card2>

      <Card2>
        <div className="section2">
          <div className="section2-image-container">
            <div class="img1">
              <img src={doc1} alt="doc1" />
            </div>
            <div
              class="img2"
              data-aos="fade"
              data-aos-delay="800"
              data-aos-easing="ease-in-out"
              data-aos-offset="100"
              data-aos-duration="1000"
              data-aos-anchor=".section2-image-container"
              data-aos-anchor-placement="center-bottom"
            >
              <img src={doc2} alt="doc2" />
            </div>
            <div
              class="img3"
              data-aos="fade"
              data-aos-easing="ease-in-out"
              data-aos-delay="600"
              data-aos-offset="100"
              data-aos-duration="1000"
              data-aos-anchor=".section2-image-container"
              data-aos-anchor-placement="center-bottom"
            >
              <img src={doc3} alt="doc3" />
            </div>
            <div
              class="img4"
              data-aos="fade"
              data-aos-easing="ease-in-out"
              data-aos-offset="100"
              data-aos-duration="section2-image-container"
              data-aos-anchor-placement="center-bottom"
            >
              <img src={blue} alt="blue" />
            </div>
            <div
              class="curve1"
              data-aos="curve1-animation"
              data-aos-easing="ease-in-out"
              data-aos-offset="10"
              data-aos-duration="2000"
            >
              <img src={curve1} alt="blue" />
            </div>
            <div
              class="curve4"
              data-aos="curve1-animation"
              data-aos-easing="ease-in-out"
              data-aos-offset="10"
              data-aos-duration="2000"
            >
              <img src={curve4} alt="blue" />
            </div>
          </div>

          <div className="content-container">
            <div
              class="curve3"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              <img src={curve3} alt="blue" />
            </div>
            <div
              className="section2-heading"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              Make a quick Consultation
            </div>
            <div
              className="section2-para"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              we understand the importance of convenient access to medical
              advice. That's why we offer a quick consultation service, allowing
              you to receive prompt and expert guidance for your healthcare
              needs.
            </div>
          </div>
        </div>
      </Card2>

      <Card2>
        <div className="section3">
          <div
            className="blueray"
            data-aos="fade-up"
            data-aos-delay="10"
            data-aos-duration="1000"
          >
            <img src={blueray} alt="" />
          </div>
          <div
            className="section3-title"
            data-aos="fade-up"
            data-aos-delay="10"
            data-aos-duration="1000"
          >
            Our Quality Services for you.
          </div>

          <div
            className="carousel-container"
            data-aos="fade-up"
            data-aos-delay="10"
            data-aos-duration="1000"
          >
            <Carousel services_data={services_data} />
          </div>
        </div>
      </Card2>

      <Card2>
        <div className="section4">
          <div className="left-content">
            <div
              className="blueheart"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              <img src={blueheart} alt="blue" />
            </div>
            <div
              className="section4-heading"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              We take care of your health
            </div>
            <div
              className="section4-para"
              data-aos="fade-up"
              data-aos-delay="10"
              data-aos-duration="1000"
            >
              Our dedicated team of healthcare professionals is committed to
              providing comprehensive care and personalized treatment plans to
              ensure your well-being and recovery.
            </div>
          </div>
          <div className="right-content">
            <img
              src={maledoc}
              alt="maledoc"
              data-aos="fade"
              data-aos-delay="400"
              data-aos-duration="2000"
            />
          </div>
        </div>
      </Card2>

      <Footer />
    </div>
  );
};

export default Services;
