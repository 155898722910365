import { useEffect, forwardRef, useImperativeHandle } from "react";
import "../css/FileInputField.scss";
import useFileInput from "../hooks/use-fileInput";
import classNames from "classnames";
const FileInputField = forwardRef((props, ref) => {
  const {
    value,
    boxContent,
    hasError,
    isValid,
    errorDesc,
    valueChangeHandler,
    inputBlurHandler,
    reset,
  } = useFileInput(props.validation);
  useEffect(() => {
    props.storeData(value, isValid);
  }, [value, isValid]);

  useImperativeHandle(ref, () => ({
    reset_data() {
      console.log("reset_data called");
      reset();
    },
    init_data() {},
  }));

  return (
    <div className="inputFileFieldContainer">
      <div
        className={classNames("inputFileField", props.className, {
          invalid: hasError,
        })}
      >
        <label>
          <div className="title">Resume</div>
          <input
            // id="file-upload"
            // value={this.state.ID_CARD}
            className="fileInput"
            type="file"
            multiple={props.multiple ?? false}
            accept={props.accept ?? ""}
            onChange={valueChangeHandler}
            onBlur={inputBlurHandler}
          />
          <div className="uploadBox">{boxContent ?? "Upload File"}</div>
        </label>
        {hasError && <div className="error_element">{errorDesc}</div>}
      </div>
    </div>
  );
});
export default FileInputField;
