import React, { useState } from "react";
import "../css/carousel.scss";
import left from "../images/services/leftarrow.png";
import right from "../images/services/rightarrow.png";

const Carousel = ({ services_data }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const previousSlide = () => {
    const newIndex =
      (currentSlideIndex - 1 + services_data.length) % services_data.length;
    setCurrentSlideIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = (currentSlideIndex + 1) % services_data.length;
    setCurrentSlideIndex(newIndex);
  };

  console.log(services_data[currentSlideIndex].desc);
  return (
    <div className="carousel">
      <button onClick={previousSlide} className="leftarrow">
        <img src={left} alt="left" />
      </button>
      {/* {services_data[currentSlideIndex].map(each => ( */}

      <div className="slide-container">
        <div className="slide-image">
          {/* 1<div className="slide-title1">
            {services_data[currentSlideIndex].title.map((line, index) => (
              <div className="slide-content-heading1">
                <span key={index}>{line.line1}</span>
                <span key={index}>{line.line2}</span>
              </div>
            ))}
          </div> */}
          <img
            src={services_data[currentSlideIndex].image}
            alt="blue"
            className="slide1"
          />
        </div>
        <div className="slide-content">
          <div className="slide-title">
            {services_data[currentSlideIndex].title.map((line, index) => (
              <div className="slide-content-heading">
                <span key={index}>{line.line1}</span>
                <span key={index}>{line.line2}</span>
              </div>
            ))}
          </div>

          {/* <div className="slide-content-para">
                            {services_data[currentSlideIndex].desc.para1}
                            {services_data[currentSlideIndex].desc.para2}
                            {services_data[currentSlideIndex].desc.para3}
                            {services_data[currentSlideIndex].desc.para4}
                            {services_data[currentSlideIndex].desc.para5}
                            {services_data[currentSlideIndex].desc.para6}
                            {services_data[currentSlideIndex].desc.para7}
                         </div>  */}
          <div className="slide-content-para">
            {services_data[currentSlideIndex].desc.map((el, index) => (
              <>{el.para}</>
            ))}
          </div>
        </div>
      </div>

      {/* ))}  */}
      <button onClick={nextSlide} className="rightarrow">
        <img src={right} alt="right" />
      </button>
    </div>
  );
};

export default Carousel;
