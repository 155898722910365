import { useEffect, forwardRef, useImperativeHandle } from "react";
// import "../css/inputTextField.scss";
import "../css/textAreaField.scss";
import classNames from "classnames";
import useTextarea from "../hooks/use-textarea";

const TextAreaField = forwardRef((props, ref) => {
  const {
    value,
    hasError,
    isValid,
    valueChangeHandler,
    inputBlurHandler,
    reset,
    storeValue,
  } = useTextarea(props.validation, props.charValidate);

  useImperativeHandle(ref, () => ({
    reset_data() {
      console.log("reset_data called");
      reset();
    },
    init_data(data) {
      console.log(data);
      storeValue(data);
    },
  }));

  useEffect(() => {
    props.storeData(value, isValid);
  }, [value, isValid]);
  return (
    <div
      className={classNames("TextAreaFieldContainer", props.containerClassName)}
      data-aos="fade-up"
      data-aos-delay="10"
      data-aos-duration="1000"
    >
      <div
        className={classNames("TextAreaField", props.className, {
          invalid: hasError,
        })}
      >
        <div className="label">
          {props.text}
          <sup>{props.superText ?? ""}</sup>
        </div>
        <textarea
          type="text"
          value={value}
          onChange={valueChangeHandler}
          onBlur={inputBlurHandler}
          // placeholder={props.placeholder ?? props.text}
          onKeyDown={(event) => {
            return true;
          }}
          required
        ></textarea>
      </div>

      {hasError && <div className="error_element">{props.error_message}</div>}
    </div>
  );
});

export default TextAreaField;
