import "../css/adpost.scss";
import WeAreHiring from "../images/seminar.jpeg";
import CloseButton from "../images/common/xmark.svg";
// import AOS from "aos";
// import "aos/dist/aos.css";
import { useEffect } from "react";
const Modal = ({ close }) => {
  // useEffect(() => {
  //   AOS.init({
  //     startEvent: "DOMContentLoaded",
  //   });
  // }, []);
  return (
    <div
      className="adpostModal"
      data-aos="fade-up"
      data-aos-delay="100"
      data-aos-duration="500"
      data-aos-once="true"
    >
      <div className="section1">
        <img src={CloseButton} onClick={close} />
      </div>
      <div className="section2">
        {/* <div className="section2_left">
            <div className="section2_left_container1">
              <div className="section2_left_p1">Sorry for the inconvenience</div>
              <div className="section2_left_p1">Valley Job Fair</div>
            </div>
            <div className="section2_left_container2">
              <div className="section2_left_p2">
                We are working hard to quickly rectify issues with our last billing cycle.
              </div>
              <div className="section2_left_p2">Sincerely apologize for any inconvenience this may have caused.!</div>
            </div>
            <div className="section2_left_container3">
              <div class="buttons"><Link to='/careers'><button class="btn-hover color-9" onClick={close}>Learn more!</button> </Link></div>

            </div>
          </div> */}
        <div className="section2_right">
          <img
            src={WeAreHiring}
            alt=""
            onClick={() =>
              window.open(
                "https://www.facebook.com/events/2679181328883626/?active_tab=discussion",
                "_blank"
              )
            }
          />
        </div>
      </div>
    </div>
  );
};
export default Modal;
